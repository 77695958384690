.logo-var{
    width: 50%;
    margin: auto;
    display: inline;
}
[dir=ltr] .vertical-layout .main-menu.theme-primary .navigation .nav-item.active a {
    background: #ff9f43 !important
}
[dir] .ag-grid-table .ag-paging-panel span[ref="lbCurrent"] {
    background-color: #ff9f43 !important;
}