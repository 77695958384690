.app-user-list {
    margin: auto;
}

.ag-header-cell-text {
    margin: auto;
}

.aaa {
    margin: auto;
}

.h5 {
    color: black;
    font-family: inherit;
    font-weight: 500;
    line-height: 1.2;
    color: #2c2c2c;
    font-size: 1.14rem;
    margin: auto;
}

.h5:hover {
    color: black !important;
}

.margin {
    margin: auto;
}

.padding {
    padding: 0% !important;
}

.mail {
    color: #ff9f43 !important;
}

.sp {
    color: #ff9f43 !important;
}

.imglogo {
    width: 40%;
    margin: auto;
}

.back {
    background-color: #ff9f43 !important;
}

.btn-voucher {
    border-color: #F7941D !important;

}

.margintop {
    margin-top: 20px;
}

.imgcart {
    width: 100%;
}

.horizintal {
    text-decoration: line-through;
    margin-right: 10px;
    font-size: 13px;
    color: red;
}

.colorbl {
    color: black;
}

.colorbl:hover {
    color: black !important;
}

.fotlink {
    padding: 5px;
    color: #ff9f43 !important;
}

.clearfix {
    font-size: 16px;
}

/* .react-ripples{
    width: 100%;
    margin: auto;
} */
[dir] .vx-radio-primary input:checked ~ .vx-radio .vx-radio--circle {
    background: #ff9f43 !important;
    box-shadow: 0 3px 12px 0 rgba(255, 159, 67, 0.4) !important;
}

[dir] .vx-radio-con input:checked ~ .vx-radio .vx-radio--circle {
    background: rgb(255, 159, 67) !important;
    box-shadow: 0 3px 12px 0 rgba(255, 159, 67, 0.4) !important;
    transform: scale(1);
}

.vx-wizard.nav.nav-tabs .step-wrapper.nav-item .step.nav-link.done .step-text {
    border-color: #ff9f43 !important;
}

[dir] .form-control:focus {
    border: 1px solid #ff9f43 !important;
}

.dropdown .dropdown-menu .dropdown-item:hover {
    color: #fff !important;
    background-color: #ff9f43 !important;
}

[dir] .badge-primary {
    background-color: #ff9f43 !important;
}

.has-icon-left .form-control-position svg:focus {
    position: relative;
    color: #ff9f43 !important;
}

.bord {
    border-radius: 25px;
    border: 2px solid #ff9f43;
    padding: 20px;
}

.user-name {
    margin-top: 7px !important;
}

.marginleft {
    max-width: 100%;
    margin: auto;
}

.cent {
    margin: auto !important;
    display: block !important;
}

@media only screen and (max-width: 500px) {
    .koHINl {
        min-width: 150px !important;
    }

    .kMXuPO {
        min-width: 150px !important;
        margin-inline-start: 10px !important;
        margin-inline-end: 10px !important;
    }

    .text-truncate {
        overflow: unset;
    }
}


.ant-ribbon {
    background-color: #9a9b9cc4 !important;
    padding: 0px 35px !important;
}

.ant-ribbon.ant-ribbon-placement-end {
    transform: rotate(
        30deg
    );
    right: -15px !important;
    border-bottom-right-radius: 0;
}

.ant-ribbon.ant-ribbon-placement-end {
    left: initial !important;
    border-bottom-right-radius: 0;
}

.ant-ribbon.ant-ribbon-placement-end.badgg {
    left: initial !important;
    right: -8px !important;
    top: -7px !important;
}

[dir] .ant-ribbon-corner::after {
    display: none;
}

#transfer {
    background-color: bisque;
    background-size: cover;
    border-radius: 5px;
}

.example {
    margin-bottom: 20px;
    margin-top: 20%;

    padding: 30px 50px;
    text-align: center;
    border-radius: 4px;
    min-height: 270px;
}

/*----   new payment   ------*/
.header-style {
    background: #121212 0% 0% no-repeat padding-box !important;
    box-shadow: 0px 3px 6px #00000029 !important;
    border: 1px solid #121212 !important;
    opacity: 1 !important;
    padding: 3% !important;

}

.title-style {
    font-size: 22px !important;
    font-weight: normal;
    letter-spacing: 1.01px !important;
    color: #FFFFFF !important;
    opacity: 1 !important;
}

.details-new {
    background: #7575751A 0% 0% no-repeat padding-box !important;
    border: 1px solid #F1F1F1 !important;
    border-radius: 8px !important;
    opacity: 1 !important;
    margin: 3% 7% !important;
    padding: 2% !important;
}

.sum-new {
    letter-spacing: 1.38px !important;
    font-size: 14px !important;
    font-weight: normal;
    color: #01BE4D !important;
    opacity: 1 !important;
}

.egp-new {
    letter-spacing: 0.69px;
    font-weight: normal !important;
    font-size: 14px !important;
    color: #000000;
    opacity: 1;
}

.order-amount-new {
    font-weight: normal !important;
    font-size: 14px !important;
    letter-spacing: 0.69px;
    color: #000000;
    opacity: 1;
}

.sum-new-balance {
    letter-spacing: 1.38px !important;
    font-size: 14px !important;
    font-weight: normal;
    color: #F84646 !important;
    opacity: 1 !important;
    margin-left: 1rem;
    margin-right: 1rem;
}

.details-new-input {
    opacity: 1 !important;
    margin: 3% 7% !important;
    margin-bottom: 0% !important;
}

.input-new {
    background: #FFFFFF 0% 0% no-repeat padding-box !important;
    border: 1px solid #D2D2D2 !important;
    border-radius: 8px !important;
    border-bottom-right-radius: 0px !important;
    border-top-right-radius: 0px !important;
    opacity: 1 !important;
    padding: 2% !important;
    font-size: 14px !important;
}

.redeem {
    background: #009E15 0% 0% no-repeat padding-box !important;
    border: 1px solid #009E15 !important;
    border-radius: 8px !important;
    padding: 0% 5% !important;
    cursor: pointer;
}

.cancel-redeem {
    background: red 0% 0% no-repeat padding-box !important;
    border: 1px solid red !important;
    border-radius: 8px !important;
    opacity: 1 !important;
    padding: 0% 5% !important;
    cursor: pointer;
}

.no-redeem {
    background: none !important;
    border: 0 !important;
    letter-spacing: 0px !important;
    color: #FFFFFF !important;
    opacity: 1 !important;
}

.input-group-append {
    margin-left: -4px !important;
}

.details-new-total {
    background: #FFEED4 0% 0% no-repeat padding-box !important;
    border: 1px solid #FFEED4 !important;
    border-radius: 8px !important;
    opacity: 1 !important;
    margin: 3% 7% !important;
    padding: 2% !important;
    margin-bottom: 0% !important;
    margin-top: 0% !important;
}

.paymentMethods {
    opacity: 1 !important;
    margin: 3% 7% !important;
    padding: 2% 0% 0% 0% !important;
    margin-bottom: 0% !important;
    margin-top: 0% !important;
    border: 1px solid #e2ebf6 !important;
    border-radius: 8px !important;
}

.paymentDetails {
    opacity: 1 !important;
    margin: 3% 20% !important;
    padding: 2% 0% 0% 0% !important;
    margin-bottom: 0% !important;
    margin-top: 0% !important;
    border-radius: 0px !important;
    border-style: solid;
    border-width: 1px;
    border-color: #e2ebf6;
    border-bottom: none;

}

.paymentDetailsSecond {
    opacity: 1 !important;
    margin: 3% 20% !important;
    padding: 2% 0% 0% 0% !important;
    margin-bottom: 0% !important;
    margin-top: 0% !important;
    border-color: #e2ebf6;
    border-radius: 0px !important;
    border-style: solid;
    border-width: 1px;
}

.descriptionPayRead {
    opacity: 1 !important;
    margin: 3% 7% !important;
    padding: 2% 0% 0% 0% !important;
    margin-bottom: 0% !important;
    margin-top: 0% !important;
}

.descriptionPay {
    background-color: #F7F7F7;
}

.fontPayment {
    font-size: 10px;
    padding: 9px;
    color: #7A7D82;
}

.fontPaymentThank {
    font-weight: bold;
    font-size: 14px;
    color: #393E46;
    opacity: 1;
}

.fontPaymentPay {
    text-align: center;
    font-size: 14px;
    font-weight: normal;
    color: #393E46;
    opacity: 1;
}

.sum-new-total {
    letter-spacing: 0.78px !important;
    font-size: 12px !important;
    font-weight: bold;
    color: #009E15;
    text-transform: capitalize;
    opacity: 1 !important;
}

.details-new-wallet {
    opacity: 1 !important;
    margin: 3% 1% !important;
    margin-bottom: 0% !important;
}

.use-wallet {
    font-weight: normal;
    font-size: 13px !important;
    letter-spacing: 0px !important;
    color: #000000 !important;
    opacity: 1;
}

.vx-checkbox-con input {
    width: 6% !important;
}

.btn-block-pay {
    width: 100% !important;
}

@media (min-width: 767px) {
    .dropdown .dropdown-menu {
        transform: translate3d(-36px, 60px, 0px) !important;
    }
}

@media (max-width: 769px) {
    .details-new {
        margin: 3% 0% !important;
    }

    .details-new-input {
        margin: 3% 0% !important;

    }

    .details-new-total {
        margin: 3% 0% !important;

    }

    .paymentMethods {
        margin: 3% 0% !important;
    }

    .details-new-wallet {
        margin: 3% 0% !important;

    }
}

.vx-checkbox-con {
    margin-left: 3rem !important;
}

@media (max-width: 425px) {
    .mob-no-padding {
        padding: 0 !important;
    }

    .order-amount-new {
        font-size: 10px !important;
    }

    .sum-new {
        font-size: 10px !important;
    }

    .egp-new {
        font-size: 10px !important;
    }

    .sum-new-balance {
        font-size: 10px !important;
        margin-left: .5rem !important;
        margin-right: .5rem !important;
    }

    .vx-checkbox-con {
        margin-left: 0 !important;
    }

    .details-new-input {
        flex-direction: column-reverse;
    }

    /*CARD PAYMENT FOR MOBILE */
    [dir] .rccs__card {
        margin: 0 !important;
        margin-inline-start: 10px !important;
    }

    .rccs__card {
        height: 160px !important;
        position: relative;
        transform-style: preserve-3d;
        transition: all 0.4s linear;
        width: 80% !important;
    }

    .rccs__number {
        font-size: 17px !important;
    }

    .rccs__name {
        bottom: 15%;
        font-family: "Consolas", "Courier", monospace;
        font-size: 14px !important;
        line-height: 1;
        overflow: hidden;
        position: absolute;
        text-overflow: ellipsis;
        text-transform: uppercase;
        width: 60%;
    }


}

.mob-no-padding {
    margin-top: 2%;
}

.thank-order {
    letter-spacing: 0.3px;
    font-size: 16px !important;
    font-weight: 500;
    color: #393E46;
    opacity: 1;
    width: 100%;
}

.thank-order-bold {
    letter-spacing: 0.3px;
    font-size: 18px !important;
    font-weight: 900;
    color: #393E46;
    opacity: 1;
    width: 100%;

}

.error {
    color: red;
}

.voucher-money {
    font-weight: 800 !important;
    font-size: 21px;
    letter-spacing: 0px;
    color: #F7941D;
    opacity: 1;
}

.gift-money {
    font-weight: 800 !important;
    font-size: 15px;
    letter-spacing: 0px;
    color: #626262;
    opacity: 1;
}

.gift-difference {
    font-weight: 800 !important;
    font-size: 16px;
    letter-spacing: 0px;
    color: #F7941D;
    opacity: 1;
}

.feathers {
    letter-spacing: 0px;
    font-weight: normal;
    font-size: 12px;
    color: #F7941D;
    opacity: 1;
}

.feathers-spin {
    letter-spacing: 0px;
    font-weight: normal;
    font-size: 15px;
    color: #F7941D;
    opacity: 1;
}

::marker {
    font-size: 12px !important;
    color: #3E3E3E !important;
    border-radius: 20px !important;
    text-align: center !important;


}

.voucher-card {
    background: #FFFFFF 0% 0% no-repeat padding-box !important;
    box-shadow: 0px 3px 6px #00000030 !important;
    border: 1px solid #FFFFFF !important;
    opacity: 1 !important;
}

.img-vol {
    background: #FFFFFF 0% 0% no-repeat padding-box !important;
    box-shadow: 0px 3px 6px #00000029 !important;
    border: 1px solid #FFFFFF !important;
    opacity: 1 !important;
    border-radius: 50%;
    padding: 3%;
}

.btn-voucher {
    background: #F7941D 0% 0% no-repeat padding-box !important;
    border: 1px solid #F7941D !important;
    border-radius: 4px !important;
    opacity: 1 !important;
    width: 80% !important;
}

.notify-badge {
    position: relative;
    height: 2rem;
    top: -23px;
    right: -25px;
    text-align: right;
    border-radius: 50%;
    z-index: 9;
}

.redeem-voucher {
    background: #F7941D 0% 0% no-repeat padding-box !important;
    border: 1px solid #F7941D !important;
    border-radius: 4px !important;
    opacity: 1 !important;
    padding: 0% 5% !important;

    cursor: pointer !important;
}

.width-voucher {
    width: 80% !important;
}

.input-new-1 {
    background: #FFFFFF 0% 0% no-repeat padding-box !important;
    border: 1px solid #F7941D !important;
    border-radius: 4px !important;
    opacity: 1 !important;
}

.padding-card {
    padding: 4rem !important;
    padding-bottom: 0 !important;
}

@media (max-width: 444px) {

    .padding-card {
        padding: 0 !important;
    }

    .voucher-money {
        font-size: 19px !important;
    }

    .feathers {
        font-size: 11px !important;
    }
}

.spanspin {
    color: #f2871e;
}

.example {
    margin-bottom: 20px;
    margin-top: 20%;

    padding: 30px 50px;
    text-align: center;
    border-radius: 4px;
    min-height: 270px;
}

.ant-spin-dot-item {
    background-color: #f2871e !important;
}

.backgroundChanged {
    width: 80% !important;
}

/* ---- Referral Codes --------*/
.total-referral {
    letter-spacing: 0px;
    font-weight: bold;
    font-size: 27px;
    color: #000000;
    opacity: 1;
}

.total-referral-money {
    font-size: 19px;
    font-weight: 300;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
}

.referral-badge {
    position: relative;
    height: 2rem;
    top: 102px;
    right: -26px;
    text-align: right;
    border-radius: 50%;
    z-index: 9;
    transform: rotate(-5deg) translateX(-65px)
}

.referralDiv {
    background-color: #F59E19;
    border: 1px solid #F59E19;
    border-radius: 8px;
    opacity: 1;
    width: 250px;
    height: 80px;
    margin-left: auto;
}

.invite {
    letter-spacing: 0px;
    color: #FFFFFF;
    text-align: center;
    font-weight: bold;
    font-size: 14px;
}


[dir=ltr] .referral-badge {
    right: -66px;
    text-align: right;
    top: 110px;
    transform: rotate(5deg) translateX(-80px);
}

.example {
    margin-bottom: 20px;
    margin-top: 20%;

    padding: 30px 50px;
    text-align: center;
    border-radius: 4px;
    min-height: 270px;
}

.ant-spin-dot-item {
    background-color: #f2871e !important;
}

.iti__flag {
    background-image: url("./images/flags.png");
}

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
    .iti__flag {
        background-image: url("./images/flags@2x.png");
    }
}

.iti__country-list {
    direction: ltr !important;
}

.iti--allow-dropdown .iti__flag-container {
    direction: ltr !important;
}

.iti {
    width: 100% !important;
}

.hide {
    display: none;
}

.valid {
    font-size: smaller;
    margin-top: 0.25rem;
    color: rgba(29, 238, 40, 0.87);
    font-weight: 700;
}

.notvalid {
    font-size: smaller;
    margin-top: 0.25rem;
    color: #ea5455;
    font-weight: 700;
}

[dir=rtl] .iti--allow-dropdown input, [dir=rtl] .iti--allow-dropdown input[type=text], [dir=rtl] .iti--allow-dropdown input[type=tel], [dir=rtl] .iti--separate-dial-code input, [dir=rtl] .iti--separate-dial-code input[type=text], [dir=rtl] .iti--separate-dial-code input[type=tel] {
    padding-right: 90px !important;
}

/*[dir=rtl].codeCol{*/
/*    direction: ltr !important;*/
/*}*/
/*.dirCol{*/
/*    direction: rtl !important;*/
/*}*/
/*.inputStyle{*/
/*    direction: ltr;*/
/*}*/
[dir] .vx-checkbox-con input:disabled + .vx-checkbox {
    cursor: not-allowed !important;
}

.redeemVerify {
    background: #009E15 0% 0% no-repeat padding-box !important;
    border: 1px solid #009E15 !important;
    border-radius: 0px 8px 8px 0px !important;
    opacity: 1 !important;
    padding: 6% 6% !important;
    cursor: pointer;
    margin-top: -3px;
    font-size: 14px;
    height: 37px !important;
    min-width: 97px;
    color: white;
}

.redeemVerifyOpactiy {
    background: #7cbd84 0% 0% no-repeat padding-box !important;
    border: 1px solid #7cbd84 !important;
    color: white;
    border-radius: 0px 8px 8px 0px !important;
    opacity: 1 !important;
    padding: 6% 6% !important;
    cursor: pointer;
    margin-top: -3px;
    font-size: 14px;
    height: 37px !important;
    min-width: 97px;
}


@media (max-width: 500px) {
    .redeemVerify {
        background: #009E15 0% 0% no-repeat padding-box !important;
        border: 1px solid #009E15 !important;
        border-radius: 0px 8px 8px 0px !important;
        opacity: 1 !important;
        padding: 9% 5% !important;
        cursor: pointer;
        margin-top: -3px;
        font-size: 12px;
        height: 37px !important;
        min-width: 82px;
        color: white;
    }

    .redeemVerifyOpactiy {
        background: #7cbd84 0% 0% no-repeat padding-box !important;
        border: 1px solid #7cbd84 !important;
        color: white;
        border-radius: 0px 8px 8px 0px !important;
        opacity: 1 !important;
        padding: 9% 5% !important;
        cursor: pointer;
        margin-top: -3px;
        font-size: 12px;
        height: 37px !important;
        min-width: 82px;
    }
}

.headerVerify {
    font-weight: bold;
    text-align: center;
    font-size: 15px;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
}

.bodyVerify {
    text-align: center;
    font-weight: 300;
    font-size: 12px;
    letter-spacing: 0px;
    color: #000000;
    opacity: 0.7;
}

.borderNone {
    border: none !important;
}

.fontButton {
    font-size: 12.5px !important;
}


.css-ae0g2e {
    margin: auto;
}

body {
    --ReactInputVerificationCode-itemWidth: 4rem;
    --ReactInputVerificationCode-itemHeight: 3rem;
    --ReactInputVerificationCode-itemSpacing: 1.2rem;
}

@media (max-width: 500px) {
    body {
        --ReactInputVerificationCode-itemWidth: 2rem;
        --ReactInputVerificationCode-itemHeight: 2.5rem;
        --ReactInputVerificationCode-itemSpacing: 1.2rem;
    }
}

@media (max-width: 319px) {
    body {
        --ReactInputVerificationCode-itemWidth: 1.5rem;
        --ReactInputVerificationCode-itemHeight: 2rem;
        --ReactInputVerificationCode-itemSpacing: 1rem;
    }
}


.css-jxah1j {
    color: #F59E19;
    background: #FFFFFF 0% 0% no-repeat padding-box !important;
    border-radius: 8px !important;
    opacity: 1 !important;
    /*box-shadow: none!important;*/
}

.css-jxah1j.is-active {
    box-shadow: inset 0 0 0 1px #F59E19 !important;
}

.err {
    color: #ea5455 !important;
    font-size: 12px !important;
    text-align: right;
}

.css-1jkbsv7 {
    margin: auto !important;
}

.verifyButton {
    margin-top: 0.2% !important;

}

.verifyinput {
    flex: 1;
}

[dir=rtl] .iti--allow-dropdown input, [dir=rtl] .iti--allow-dropdown input[type=text], [dir=rtl] .iti--allow-dropdown input[type=tel], [dir=rtl] .iti--separate-dial-code input, [dir=rtl] .iti--separate-dial-code input[type=text], [dir=rtl] .iti--separate-dial-code input[type=tel] {
    padding-left: 2% !important;
}

[dir=rtl] .ReactInputVerificationCode__container {
    direction: ltr !important;
}

.disableSave {
    background-color: #ff9f4378 !important;
    border-color: #ff9f4378 !important;
}

@media (max-width: 800px) {
    .paymentDetails {
        margin: 0% 0% !important;
    }

    .paymentDetailsSecond {
        margin: 0% 0% !important;
    }

    .descriptionPayRead {
        margin: 3% 3% !important;
    }
}

@media (max-width: 500px) {
    .mobileStyle {
        padding: 0px !important;
    }


    .mobile-view {
        width: 100% !important;
        margin: auto;
    }
}

.addFunds {
    background-color: #545454 !important;
    color: white !important;
}

.transferMoney {
    background-color: #06B44C !important;
    color: white !important;

}

.variianceWallet {
    color: #ff9f43 !important;
}

.variianceBalance {
    color: #06B44C !important;
}

/*a{*/
/*    color: #626262 !important;*/
/*}*/
.generalColor {
    color: #626262 !important;
}

/* radio buttons payment methods */

/* Customize the label (the container) */
.containerPayment {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 15px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

/* Hide the browser's default radio button */
.containerPayment input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

/* Create a custom radio button */
.checkmarkPayment {
    position: absolute;
    top: 1px;
    left: 0;
    height: 18px;
    width: 18px;
    background-color: #fff;
    border-radius: 50%;
    border: 1px #F7941D solid;

}

/* On mouse-over, add a grey background color */
.containerPayment:hover input ~ .checkmarkPayment {
    background-color: rgba(247, 148, 29, 0.51);
}

/* When the radio button is checked, add a blue background */
.containerPayment input:checked ~ .checkmarkPayment {
    background-color: #F7941D;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.checkmarkPayment:after {
    content: "";
    position: absolute;
    display: none;
}

/* Show the indicator (dot/circle) when checked */
.containerPayment input:checked ~ .checkmarkPayment:after {
    display: block;
}

/* Style the indicator (dot/circle) */
.containerPayment .checkmarkPayment:after {
    top: 5.1px;
    left: 5.2px;
    width: 5px;
    height: 5px;
    border-radius: 50%;
    background: white;
}

.marginPoint {
    margin-top: 3% !important;
    margin-left: 0 !important;
}

@media (min-width: 1000px) {

    .marginPoint {
        margin-left: 3.2rem !important;
    }
}

.fontRules {
    font-size: 11px;
    margin-top: 2px;
}

.orderNumber {
    font-size: 15px;
    color: #000000;
    opacity: 0.37;
}

.numberfawry {
    color: black;
    font-size: 22px;
    font-weight: 900;
}

.expiration {
    letter-spacing: 0px;
    font-weight: bold;
    font-size: 14px;
    color: #000000;
    opacity: 1;
}

.expirationDate {
    letter-spacing: 0px;
    font-weight: normal;
    font-size: 14px;
    color: #A80B0B !important;
    opacity: 1;
}

.moneyData {
    letter-spacing: 0px;
    font-weight: normal;
    font-size: 14px;
    color: #009135 !important;
    opacity: 1;
}

.marginPhoto {
    margin: 0% 1%;
}

.formDesign {
    padding: 1% 7%;
}

.back-to-courses {
    color: #F7941D;
    transition: all .4s;
    border: 1px solid rgba(190, 190, 190, 0.31);
    box-shadow: 0 10px 8px rgba(190, 190, 190, 0.5);
}

.back-to-courses:hover {
    color: #F7941D;
    background-color: rgba(185, 185, 185, 0.09);
    box-shadow: 0 3px 3px rgba(190, 190, 190, 1);
    transform: translateY(2px);
    border-radius: 2%;
}

/*.back-to-courses:after {*/
/*    content: " → ";*/
/*    color: #F7941D;*/

/*}*/


/* CARD PAYMENT FOR WEB*/
.rccs__number {
    font-family: "Consolas", "Courier", monospace;
    font-size: 20px;
    position: absolute;
    top: 45%;
}

.rccs__card {
    height: 182.873px;
    position: relative;
    transform-style: preserve-3d;
    transition: all 0.4s linear;
    width: 290px;
}

[dir] .rccs__card {
    margin: 0 auto;
}

.rccs__name {
    bottom: 15%;
    font-family: "Consolas", "Courier", monospace;
    font-size: 17px;
    line-height: 1;
    overflow: hidden;
    position: absolute;
    text-overflow: ellipsis;
    text-transform: uppercase;
    width: 60%;
}
